export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export const siteUrl = isDev
  ? 'http://localhost:8000'
  : process.env.GATSBY_SITE_URL || 'https://earth.fm';

export const wpGraphqlUrl = isDev
  ? 'http://headlesswp.earthfm.local/graphql'
  : process.env.GATSBY_WPGRAPHQL_URL || 'https://wp.earth.fm/graphql';

export const frontendHost = new URL(siteUrl).host;
export const wpHost = new URL(wpGraphqlUrl).host;
export const wpUrl = wpGraphqlUrl.replace('/graphql', '');

export const isLiveEnv = isProd && frontendHost === 'earth.fm';

export const algoliaIndexName = process.env.GATSBY_ALGOLIA_INDEX_NAME || 'earth_fm';

export const pages = {
  home: '/',
  search: '/search/',
  signIn: '/signin/',
  signUp: '/signup/',
  signInWithEmail: '/sign-in-with-email/',
  checkSignInEmail: '/check-sign-in-email/',
  becomeAMember: '/become-a-member/',
  memberProfileSetup: '/member-profile-setup/',
  memberLibrary: '/member-library/',
  memberProfileSettings: '/member-settings/profile/',
  memberAccountSettings: '/member-settings/account/',
  memberMembershipSettings: '/member-settings/membership/',
  memberPrivacySettings: '/member-settings/privacy/',
  createPlaylist: '/create-playlist/',
  memberPlaylistBase: '/member-playlist/',
  about: '/about-us/',
  contact: '/contact-us/',
  privacyPolicy: '/privacy/',
  newsletter: '/newsletter-subscription/',
  sitemap: '/sitemap/',
  blog: '/blog/',
  glossary: '/glossary/',
  playlists: '/playlists/',
  podcast: '/podcast/',
  recordings: '/recordings/',
  shop: '/shop/',
  cart: '/cart/',
  donationCompleted: '/donation-completed/',
  artists: '/artists/',
  scholarship: '/scholarship/',
  desktopAppSignIn: '/desktop-app-sign-in',
  giftCard: '/gift-card/',
  thankYou: '/thank-you/',
  app: '/app/',
  externalPrivacyPolicyUrl:
    'https://platform.illow.io/#/policy/privacy/cd6f77c6-422d-4bfa-9e4f-67208097132a',
  externalCookieUrl:
    'https://platform.illow.io/#/policy/cookie/cd6f77c6-422d-4bfa-9e4f-67208097132a',
  financialAndImpactReportPage: '/financial-and-impact-report/',
  grants: '/field-recording-grant/',
  quietPlacesFinder: '/the-quietest-places-in-the-worlds-loudest-cities/',
  desktopApp: '/earth-fm-for-desktop/',
};

export const customAppPageUris = ['/rain-sounds-app/'];

export const apiUrls = {
  newsletterSubscription: '/api/newsletter-subscription-handler',
  appReleaseSubscription: '/api/app-release-subscription-handler',
  trendingRecordings: '/api/trending-recordings',
};

export const earthFmPlaylistOwner = {
  id: 'earth-fm',
  name: 'Earth.fm',
};

export const nonLoggedInUserTimeLimit = 20 * 60; // 20 minutes in seconds
export const loggedInNonMemberTimeLimit = 30 * 60; // 30 minutes in seconds
// Using a non-guessable key here so that a user can't easily bypass the time limit
export const totalPlayedStorageKey = 'earth.fm:audio-player:6e3e4e3e';

export const storageKeys = {
  scholarship: 'redirect_from_scholarship',
  giftCard: 'earth.fm:gift-card',
};

export const newsletterListIds = {
  newsletter: 6,
  appRelease: 19,
};

export const appStoreLinks = {
  ios: 'https://apps.apple.com/us/app/earth-fm-nature-sounds/id6464324601',
  android: 'https://play.google.com/store/apps/details?id=com.earthfm',
};
